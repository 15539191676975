import { IconButton } from "@flash-tecnologia/hros-web-ui-v2";
import { CloseContainer, StyledModal } from "./styles";
import { useCallback, useEffect, useState } from "react";
(window as any).awsWafCookieDomainList = [
  "flashapp.dev",
  "flashapp.site",
  "staging-platform-hros-web-root.flashapp.site",
  "flashapp.services",
];
import { useCaptcha, Captcha } from "@flash-tecnologia/flashstage-auth";
import { dispatchToast } from "../../utils";

export const WafCaptchaModal = () => {
  const [open, setOpen] = useState(false);

  const onCaptchaSuccess = () => {
    setOpen(false);
  };

  const onCaptchaError = () => {
    dispatchToast({
      type: "error",
      content: "Erro ao validar o captcha.",
    });
  };

  const onShowCaptcha = useCallback(() => {
    if (!open) {
      setOpen(true);
    }
  }, [open]);

  useCaptcha({
    onShowCaptcha,
  });

  return (
    <StyledModal
      open={open}
      onClose={() => setOpen(false)}
      header={
        <CloseContainer>
          <IconButton
            icon="IconX"
            onClick={() => setOpen(false)}
            variant="line"
            size="small"
          />
        </CloseContainer>
      }
    >
      <Captcha onSuccess={onCaptchaSuccess} onError={onCaptchaError} />
    </StyledModal>
  );
};
