import { ThemeProvider } from "@flash-tecnologia/hros-web-ui-v2";
import { WafCaptchaModal } from "./components/WafCaptchaModal";

export default function Root() {
  return (
    <ThemeProvider>
      <WafCaptchaModal />
    </ThemeProvider>
  );
}
