import styled from "styled-components";

import { Modal } from "@flash-tecnologia/hros-web-ui-v2";

const CloseContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 24px 24px 0px 0px;
`;

const StyledModal = styled(Modal)`
  && {
    &.modal-custom-theme .modal-container {
      @media screen and (max-width: 420px) {
        max-width: 98%;
      }
    }
    &.modal-custom-theme .modal-content-area {
      padding-top: 0px;
      @media screen and (max-width: 700px) {
        padding: 35px;
      }
      @media screen and (max-width: 420px) {
        padding: 5px;
      }
    }
  }
`;

export { CloseContainer, StyledModal };
